import React from "react";
import { ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";
import Footer from "./Footer";
import Header from "./Header";
import InfoAmbiente from "./InfoAmbiente";

export default function ScreenTemplate({
  navigation,
  route,
  openModal,
  user,
  content,
  back = false,
}) {
  const [headerText, setHeaderText] = React.useState();
  const props = { navigation, route, openModal, user, content, setHeaderText };

  return (
    <View>
      <Header
        navigation={navigation}
        icon="filter"
        onPress={openModal}
        imagen={user.imagen}
        back={back}
        name={headerText}
      />
      <InfoAmbiente />
      <ScrollView style={{ height: `calc(100vh - 110px)` }}>
        {content(props)}
      </ScrollView>
      <Footer />
    </View>
  );
}
