import React, { useEffect, useState } from "react";
import { REACT_APP_AMBIENTE } from "@env";
import { View } from "react-native";
import { Text } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";

export default function InfoAmbiente() {
  const [muestraAmbiente, setMuestraAmbiente] = useState(false);
  const [texto, setTexto] = useState("Apuntando a");

  useEffect(() => {
    console.log(
      window.location.hostname,
      window.location.hostname.indexOf("srvpineal.cl")
    );
    if (
      REACT_APP_AMBIENTE !== "PRODUCCION" ||
      window.location.hostname.indexOf("app.srvpineal.cl") === -1
    ) {
      if (window.location.hostname.indexOf("srvpineal.cl") !== -1) {
        setTexto("Ambiente de");
      }
      setMuestraAmbiente(true);
    }
  }, [window.location.hostname]);

  return (
    <View
      style={{
        position: "absolute",
        width: "100%",
        top: 20,
        height: 30,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {muestraAmbiente && (
        <Text
          style={{
            color: ColoresPineal.blanco,
            backgroundColor: ColoresPineal.rojo,
            fontSize: 25,
            fontWeight: "bold",
            paddingHorizontal: 20,
            paddingVertical: 5,
            borderRadius: 25,
          }}
        >
          {`${texto} ${REACT_APP_AMBIENTE}`}
        </Text>
      )}
    </View>
  );
}
