import { TableCell, TableRow } from "@material-ui/core";
import { useRoute } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import React from "react";
import { Dimensions, Platform, ScrollView, View } from "react-native";
import "react-native-gesture-handler";
import {
  Badge,
  Checkbox,
  Colors,
  Dialog,
  IconButton,
  List,
  ProgressBar,
  Text,
} from "react-native-paper";
import XLSX from "xlsx";
import BotonesEmpresaCargo from "../components/BotonesEmpresaCargo";
import CustomButton from "../components/CustomButton";
import CustomConfirmacion from "../components/CustomConfirmacion";
import CustomFabGroup from "../components/CustomFabGroup";
import CustomMuiDataTable from "../components/CustomMuiDataTable";
import CustomStepIndicator from "../components/CustomStepIndicator";
import CustomTextInput from "../components/CustomTextInput";
import LoadingIndicator from "../components/LoadingIndicator";
import SelectAutocompletar from "../components/SelectAutocompletar";
import DocumentosService from "../services/DocumentosService";
import EmpresaService from "../services/EmpresaService";
import PostulanteService from "../services/PostulanteService";
import ProcesosService from "../services/ProcesosService";
import UserService from "../services/UserService";
import ColoresPineal from "../styles/ColoresPineal";
import styles from "../styles/style";
import Etapas from "../utils/Etapas";
import Roles from "../utils/Roles";
import Evaluaciones from "./modal/Evaluaciones";

const windowHeight = Dimensions.get("window").height;
const etapas = Etapas();

export default function Postulantes({
  navigation,
  user,
  etapa,
  nombreSigEtapa,
  nombreAntEtapa,
  estado,
  postulantes,
  setPostulantes,
  empresas,
  cargos,
  setCargos,
  empresa,
  setEmpresa,
  cargo,
  setCargo,
  filtraPsicologos,
  psicologosFiltrados,
  setPsicologosFiltrados,
  psicologos,
  test,
  obtieneLabels,
  setHeaderText,
}) {
  const [actualizar, setActualizar] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [postulantesFiltrados, setPostulantesFiltrados] = React.useState([]);
  const [showSpinnerEmpresas, setShowSpinnerEmpresas] = React.useState(true);
  const [enviados, setEnviados] = React.useState(0);
  const [enviadosError, setEnviadosError] = React.useState(0);
  const [psicologoSelecionado, setPsicologoSelecionado] = React.useState(null);
  const [testSeleccionado, setTestSeleccionado] = React.useState(null);
  const [columnasView] = React.useState({
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
  });
  const [datosPostulante, setDatosPostulante] = React.useState([]);
  const [fecha, setFecha] = React.useState(new Date());
  const [hora, setHora] = React.useState(new Date());

  const [datosPanel] = React.useState({
    comentario: "",
    comentarioEntrevistaPsicolaboral: "",
    comentarioExamenPreocupacional: "",
    entrevistaPsicolaboral: "",
    examenPreocupacional: "",
    fecha: null,
    hora: null,
    idPostulante: 0,
    idProceso: 0,
    panelistas: [],
    resultado: "",
    usuario: user.user,
    fechaHora: "",
  });
  const [archivo, setArchivo] = React.useState(null);
  const [state, setState] = React.useState({ open: false });
  const [habilitarEnvio, setHabilitarEnvio] = React.useState(false);
  const [seleccionaTodos, setSeleccionaTodos] = React.useState(false);
  const { open } = state;
  const [opcionesModal] = React.useState({
    dismissable: true,
    tituloModal: null,
    contenidoModal: () => {},
    botonModal: () => {},
  });

  const route = useRoute();
  const { proceso } =
    route.params === undefined ? { proceso: undefined } : route.params;

  const selectEmpresa = (empresa, cargo = -1) => {
    setEmpresa(empresa);
    setCargo({ cargoProceso: "Seleccione Cargo / Proceso", idCargo: -1 });
    setCargos([]);
    setPostulantes([]);
    obtieneCargos(empresa, cargo);
    setVisible(false);
    setActualizar(!actualizar);
  };

  const selectCargo = (cargo) => {
    setPostulantes([]);
    setShowSpinner(true);
    setCargo(cargo);
    obtienePostulantes(cargo);
    setVisible(false);
    setActualizar(!actualizar);
  };

  const ratingPostulante = (postulante, rating) => {
    if (
      UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.PSICOLOGO,
        Roles.ADMIN_EMPRESA,
      ])
    ) {
      spinnerModal("Actualizando Postulante.");
      setActualizar(actualizar);
      setTimeout(() => {
        PostulanteService.modificarPtoPostulante(
          user.token,
          rating + 1,
          postulante.idPostulante,
          cargo.idProceso,
          estado
        ).then((resp) => {
          if (resp.codeResponse === 200) {
            postulante.estrellas = rating + 1;
            alertaModal("info", "Postulante actualizado exitosamente.");
          } else {
            alertaModal(
              "error",
              "Se ha producido un error, intente más tarde."
            );
          }
        });
      }, 500);
    }
  };

  const openModal = (opciones = undefined) => {
    if (opciones !== undefined) {
      if (opciones.tituloModal !== undefined) {
        opcionesModal.tituloModal = opciones.tituloModal;
      }
      if (opciones.contenidoModal !== undefined) {
        opcionesModal.contenidoModal = opciones.contenidoModal;
      }
      if (opciones.botonModal !== undefined) {
        opcionesModal.botonModal = opciones.botonModal;
      }
      if (opciones.dismissable !== undefined) {
        opcionesModal.dismissable = opciones.dismissable;
      }
    }
    setVisible(true);
    setActualizar(!actualizar);
  };

  const closeModal = () => {
    setVisible(false);
    setActualizar(!actualizar);
  };

  const viewDetails = (postulante) => {
    postulante["recargar"] = true;
    navigation.navigate("Detalle Postulante", {
      postulante: postulante,
      cargo: cargo,
    });
  };

  const totalSeleccionados = () => {
    let postulantesEtapa = obtienePostulantesEtapa(true);
    return postulantesEtapa.filter((postulante) => {
      if (postulante.check === undefined) {
        return false;
      }
      return postulante.check;
    }).length;
  };

  const desseleccionaTodos = () => {
    setSeleccionaTodos(false);
    obtienePostulantesEtapa(true)
      .filter((postulante) => {
        if (postulante.check === undefined) {
          return false;
        }
        return postulante.check;
      })
      .forEach((postulante) => {
        postulante.check = false;
      });
  };

  const obtieneCargos = (empresa, idCargo = -1) => {
    ProcesosService.obtieneProcesos(user.token, empresa.id).then(
      (respuesta) => {
        if (respuesta.codigoRespuesta === 200) {
          let cargos = respuesta.entidad;
          setCargos(cargos);
          if (cargo !== -1) {
            let cargo = cargos.find((crg) => crg.idProceso === idCargo);
            if (cargo !== undefined) {
              alertaModal("info", "Se obtuvo infomación exitosamente.");
              selectCargo(cargo);
            }
          }
          setActualizar(!actualizar);
        }
      }
    );
  };

  const obtienePostulantes = (cargo, accion) => {
    var idEstado = 0;
    if (accion !== "envio") {
      idEstado = estado;
    }
    PostulanteService.obtienePostulantesProceso(
      user.token,
      cargo.idProceso,
      0
    ).then((respuesta) => {
      setShowSpinner(false);
      if (respuesta.codigoRespuesta === 200) {
        let postulantes = respuesta.entidad;
        postulantes.forEach((postulante) => {
          postulante["viewDetail"] = false;
        });
        setPostulantes(postulantes);
        setActualizar(!actualizar);
        navigation.navigate("Procesos en Curso");
      } else {
        alertaModal(
          "error",
          "Se produjo un error al intentar obtener los datos, intente mas tarde."
        );
      }
    });
  };

  const enviarEtapaSiguiente = () => {
    let total = totalSeleccionados();
    setHabilitarEnvio(false);
    openModal({
      dismissable: false,
      tituloModal: "Enviando postulantes a " + nombreSigEtapa,
      contenidoModal: (opciones) => {
        return (
          <View>
            <ProgressBar progress={opciones.enviados / total} color="#f6a623" />
            <Text style={{ paddingTop: 10, textAlign: "right" }}>
              {opciones.enviados + "/" + total}
            </Text>
          </View>
        );
      },
      botonModal: (opciones) => {
        return (
          <CustomButton
            onPress={() => {
              desseleccionaTodos();
              closeModal();
            }}
            disabled={!opciones.habilitarEnvio}
            textoButton="Aceptar"
          />
        );
      },
    });

    var postulantesSeleccionados = obtienePostulantesEtapa(true).filter(
      (postulante) => {
        if (postulante.check === undefined) {
          return false;
        }
        return postulante.check;
      }
    );

    postulantesSeleccionados.forEach(async (postulante, index) => {
      postulante.etapa = estado + 1;
      postulante.check = false;
      var resp = await PostulanteService.cambioEstadoPostulante(
        user.token,
        cargo.idProceso,
        postulante.idPostulante,
        estado + 1
      );
      if (index + 1 === total) {
        setHabilitarEnvio(true);
      }
      setEnviados(index + 1);
    });
  };

  const enviarEtapaAnterior = () => {
    let total = totalSeleccionados();
    setHabilitarEnvio(false);
    openModal({
      dismissable: false,
      tituloModal: "Enviando postulantes a " + nombreAntEtapa,
      contenidoModal: (opciones) => {
        return (
          <View>
            <ProgressBar
              progress={opciones.enviados / total}
              color={ColoresPineal.anaranjado}
            />
            <Text style={{ paddingTop: 10, textAlign: "right" }}>
              {opciones.enviados + "/" + total}
            </Text>
          </View>
        );
      },
      botonModal: (opciones) => {
        return (
          <CustomButton
            onPress={() => {
              desseleccionaTodos();
              closeModal();
            }}
            disabled={!opciones.habilitarEnvio}
            textoButton="Aceptar"
          />
        );
      },
    });

    var postulantesSeleccionados = obtienePostulantesEtapa(true).filter(
      (postulante) => {
        if (postulante.check === undefined) {
          return false;
        }
        return postulante.check;
      }
    );

    postulantesSeleccionados.forEach(async (postulante, index) => {
      postulante.etapa = estado - 1;
      postulante.check = false;
      var resp = await PostulanteService.cambioEstadoPostulante(
        user.token,
        cargo.idProceso,
        postulante.idPostulante,
        estado - 1
      );
      if (index + 1 === total) {
        setHabilitarEnvio(true);
      }
      setEnviados(index + 1);
    });
  };

  const descargaArchivos = (postulante) => {
    DocumentosService.listaDocumentos(user.token, postulante.idPostulante).then(
      (archivos) => {
        openModal({
          tituloModal: "Descarga de archivos",
          contenidoModal: () => {
            if (archivos.length === 0) return <Text>Sin documentos</Text>;
            return (
              <ScrollView style={{ maxHeight: (windowHeight * 4) / 5 - 200 }}>
                {archivos.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ width: "100%" }}>
                        {item.fileName}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          icon={item.video ? "eye-off" : "eye"}
                          size={20}
                          onPress={() => {
                            if (!item.video) {
                              closeModal();
                              navigation.navigate("Documento", {
                                archivo: item,
                              });
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          icon="file-download-outline"
                          size={20}
                          onPress={() =>
                            DocumentosService.obtieneDocumento(user.token, item)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </ScrollView>
            );
          },
          botonModal: () => {
            return (
              <CustomButton
                onPress={() => closeModal()}
                textoButton="Aceptar"
              />
            );
          },
        });
        setActualizar(!actualizar);
      }
    );
  };

  const descargarTest = (postulante) => {
    openModal({
      tituloModal: "Descargar Resultado Test",
      contenidoModal: (opciones) => {
        test = [];
        if (postulante.estadoTestDisc === "COMPLETO") {
          //Correcciones
          test.push({
            onClick: () => {
              spinnerModal("Descargando Archivo");
              DocumentosService.obtieneDocumentoTestDisc(
                user.token,
                cargo.idProceso,
                postulante.idPostulante
              ).then((respuesta) => {
                closeModal();
                if (
                  respuesta.response.headers
                    .get("Content-Type")
                    .includes("application/json")
                ) {
                  alertaModal("warn", "Sin documento");
                } else {
                  var a = document.createElement("a");
                  a.href = respuesta.url;
                  // a.download = archivo.fileName;
                  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                  a.click();
                  a.remove();
                }
              });
            },
            nombre: "Disc",
          });
        }
        if (postulante.estadoTestWonderlic === "COMPLETO") {
          test.push({
            onClick: () => {
              spinnerModal("Descargando Archivo");
              let data = [];
              data.push({
                RUT: postulante.rut,
                NOMBRE: `${postulante.nombre} ${postulante.apPaterno} ${postulante.apMaterno}`,
                RESULTADO: postulante.resultadoTestWonderlic,
              });
              var ws = XLSX.utils.json_to_sheet(data);
              var wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, "Wonderlic");
              XLSX.writeFile(wb, "Repsultados_Test_Wonderlic.xlsx");
              closeModal();
            },
            nombre: "Wonderlic",
          });
        }
        if (postulante.estadoTestPrp === "COMPLETO") {
          test.push({
            onClick: () => {
              spinnerModal("Descargando Archivo");
              DocumentosService.obtieneDocumentoTest(
                user.token,
                postulante.uuidPrp
              ).then((respuesta) => {
                closeModal();
                if (
                  respuesta.response.headers
                    .get("Content-Type")
                    .includes("application/json")
                ) {
                  alertaModal("warn", "Sin documento");
                } else {
                  var a = document.createElement("a");
                  a.href = respuesta.url;
                  // a.download = archivo.fileName;
                  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                  a.click();
                  a.remove();
                }
              });
            },
            nombre: "Prp",
          });
        }
        if (postulante.estadoTestBarrat === "COMPLETO") {
          test.push({
            onClick: () => {
              spinnerModal("Descargando Archivo");
              DocumentosService.obtieneDocumentoTest(
                user.token,
                postulante.uuidBarrat
              ).then((respuesta) => {
                closeModal();
                if (
                  respuesta.response.headers
                    .get("Content-Type")
                    .includes("application/json")
                ) {
                  alertaModal("warn", "Sin documento");
                } else {
                  var a = document.createElement("a");
                  a.href = respuesta.url;
                  // a.download = archivo.fileName;
                  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                  a.click();
                  a.remove();
                }
              });
            },
            nombre: "Barrat",
          });
        }
        if (test.length === 0) {
          test.push({
            onClick: () => {},
            nombre: "Sin Test Completados",
          });
        }

        return (
          <View>
            <ScrollView style={{ maxHeight: (windowHeight * 4) / 5 - 200 }}>
              {test.map((item, index) => {
                return (
                  <List.Item
                    onPress={() => item.onClick()}
                    key={index}
                    title={item.nombre}
                    left={(props) => <List.Icon icon={"file-document"} />}
                  />
                );
              })}
            </ScrollView>
          </View>
        );
      },
      botonModal: (opciones) => {
        return (
          <CustomButton
            onPress={() => {
              closeModal();
            }}
            textoButton="Cancelar"
          />
        );
      },
    });
  };

  const subirArchivo = (postulante) => {
    let archivo = undefined;
    const pickDocument = async () => {
      let result = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        multiple: true,
        copyToCacheDirectory: true,
      });
      return result;
    };
    openModal({
      tituloModal: "Subir archivo",
      contenidoModal: (opciones) => {
        return (
          <View>
            <CustomButton
              onPress={async () => {
                archivo = await pickDocument();
                setArchivo(archivo);
                setActualizar(!actualizar);
              }}
              textoButton="Seleccione Archivos"
            />
            {opciones.archivo !== null &&
              [...opciones.archivo.output].map((file) => {
                return <List.Item title={file.name} />;
              })}
          </View>
        );
      },
      botonModal: (opciones) => {
        return (
          <CustomConfirmacion
            onPressCancelar={() => {
              closeModal();
            }}
            textoButton="Cancelar"
            onPressAceptar={() => {
              spinnerModal("Subiendo archivo.");
              setActualizar(actualizar);
              setTimeout(() => {
                enviaDocumento(postulante.idPostulante, opciones.archivo);
              }, 500);
            }}
            disabled={opciones.archivo === null}
            textAceptar="Subir"
          />
        );
      },
    });
  };

  const enviaDocumento = (idPostulante, archivo) => {
    DocumentosService.cargarDocumento(
      user.token,
      idPostulante,
      archivo.output
    ).then((resp) => {
      setArchivo(null);
      if (resp.codeResponse === 200) {
        alertaModal("info", "Archivo cargado exitosamente.");
      } else {
        alertaModal("error", "Se ha producido un error, intente más tarde.");
      }
    });
  };

  const spinnerModal = (mensaje) => {
    openModal({
      tituloModal: "Procesando",
      contenidoModal: () => {
        return <LoadingIndicator texto={mensaje} />;
      },
      botonModal: () => {},
    });
  };

  const alertaModal = (tipoAlerta, mensaje) => {
    let icon;
    switch (tipoAlerta) {
      case "info":
        icon = {
          name: "check-circle-outline",
          color: "#44B42E",
        };
        break;
      case "warn":
        icon = {
          name: "information-outline",
          color: "#D8B62A",
        };
        break;
      case "error":
        icon = {
          name: "close-circle-outline",
          color: "#a53737",
        };
        break;
    }
    openModal({
      tituloModal: "",
      contenidoModal: () => (
        <List.Item
          titleNumberOfLines={2}
          title={mensaje}
          left={() => (
            <List.Icon
              icon={icon.name}
              color={icon.color}
              style={{ transform: [{ scale: 3 }], marginRight: 30 }}
            />
          )}
        />
      ),
      botonModal: () => (
        <CustomButton onPress={() => closeModal()} textoButton="Aceptar" />
      ),
    });
  };

  const envioPsicologo = (psicologoSelecionado) => {
    let idsPostulantes = [];

    obtienePostulantesEtapa(true)
      .filter((postulante) => {
        if (postulante.check === undefined) {
          return false;
        }
        return postulante.check;
      })
      .forEach((postulante) => {
        idsPostulantes.push(postulante.idPostulante);
      });

    spinnerModal("Asignando psicólogo.");
    setActualizar(actualizar);
    setTimeout(() => {
      PostulanteService.asignarPsicologo(
        user.token,
        cargo.idProceso,
        psicologoSelecionado.id,
        idsPostulantes
      ).then((resp) => {
        if (resp.codeResponse === 200) {
          alertaModal(
            "info",
            "El psicólogo fue asignado exitosamente a los postulantes seleccionados."
          );
        } else {
          alertaModal("error", "Se produjo un problema intente mas tarde.");
        }
      });
    }, 500);
  };

  const enviarTest = async (testSeleccionado) => {
    closeModal();
    let total = totalSeleccionados();
    setHabilitarEnvio(false);
    openModal({
      dismissable: false,
      tituloModal:
        "Asignando TEST " + testSeleccionado.nombre + " a postulantes",
      contenidoModal: (opciones) => {
        return (
          <View>
            <ProgressBar
              progress={opciones.enviados / total}
              color={ColoresPineal.anaranjado}
            />
            <Text style={{ paddingTop: 10, textAlign: "right" }}>
              {`(Procesados: ${opciones.enviados} OK - ${opciones.enviadosError} Error) / Total ${total}`}
            </Text>
          </View>
        );
      },
      botonModal: (opciones) => {
        return (
          <CustomButton
            onPress={() => {
              desseleccionaTodos();
              closeModal();
            }}
            disabled={!opciones.habilitarEnvio}
            textoButton="Aceptar"
            cargando={!opciones.habilitarEnvio}
          />
        );
      },
    });

    setActualizar(actualizar);

    var postulantesSeleccionados = obtienePostulantesEtapa(true).filter(
      (postulante) => {
        if (postulante.check === undefined) {
          return false;
        }
        return postulante.check;
      }
    );

    let totalOk = 0;
    let totalNoOk = 0;
    for (let index = 0; index < postulantesSeleccionados.length; index++) {
      const postulante = postulantesSeleccionados[index];
      var resp = await PostulanteService.enviarTest(
        user.token,
        postulante.idPostulante,
        cargo.idProceso,
        testSeleccionado.idTest
      );
      if (resp.codeResponse === 200) {
        postulante.estadoTestDisc = "ENVIADO";
        totalOk++;
      } else {
        totalNoOk++;
      }
      if (totalOk + totalNoOk === total) {
        setHabilitarEnvio(true);
      }
      setEnviados(totalOk);
      setEnviadosError(totalNoOk);
    }
  };

  const asignarPsicologo = () => {
    openModal({
      tituloModal: "Asignar psicólogo",
      contenidoModal: (opciones) => {
        return (
          <SelectAutocompletar
            lista={psicologos}
            nombreLabel={"nombre"}
            nombreValue={"id"}
            onSelect={(item) => setPsicologoSelecionado(item)}
          />
        );
      },
      botonModal: (opciones) => {
        return (
          <CustomButton
            disabled={!opciones.habilitarPsicologoSeleccionado}
            onPress={() => envioPsicologo(opciones.psicologoSelecionado)}
            textoButton="Aceptar"
          />
        );
      },
    });
    setActualizar(!actualizar);
  };

  const asignarTest = () => {
    openModal({
      tituloModal: "Asignar TEST",
      contenidoModal: (opciones) => {
        return (
          <View>
            <ScrollView style={{ maxHeight: (windowHeight * 4) / 5 - 200 }}>
              {opciones.test.map((item, index) => {
                return (
                  <List.Item
                    onPress={() => setTestSeleccionado(item)}
                    key={index}
                    title={item.nombre}
                    left={(props) => (
                      <List.Icon
                        icon={
                          opciones.testSeleccionado !== null &&
                          opciones.testSeleccionado.idTest === item.idTest
                            ? "check"
                            : ""
                        }
                      />
                    )}
                  />
                );
              })}
            </ScrollView>
          </View>
        );
      },
      botonModal: (opciones) => {
        return (
          <CustomButton
            disabled={opciones.testSeleccionado === null}
            onPress={() => enviarTest(opciones.testSeleccionado)}
            textoButton="Aceptar"
          />
        );
      },
    });
    setActualizar(!actualizar);
  };

  // const reenviarCorreo = () => {
  //   let total = totalSeleccionados();
  //   setHabilitarEnvio(false);
  //   openModal({
  //     dismissable: false,
  //     tituloModal: "Reenviando correo(s)",
  //     contenidoModal: (opciones) => {
  //       return (
  //         <View>
  //           <ProgressBar progress={opciones.enviados / total} color="#f6a623" />
  //           <Text style={{ paddingTop: 10, textAlign: "right" }}>
  //             {opciones.enviados + "/" + total}
  //           </Text>
  //         </View>
  //       );
  //     },
  //     botonModal: (opciones) => {
  //       return (
  //         <CustomButton
  //           onPress={() => {
  //             desseleccionaTodos();
  //             closeModal();
  //           }}
  //           disabled={!opciones.habilitarEnvio}
  //           textoButton="Aceptar"
  //         />
  //       );
  //     },
  //   });

  //   var postulantesSeleccionados = obtienePostulantesEtapa(true).filter(
  //     (postulante) => {
  //       if (postulante.check === undefined) {
  //         return false;
  //       }
  //       return postulante.check;
  //     }
  //   );

  //   postulantesSeleccionados.forEach(async (postulante, index) => {
  //     var resp = await PostulanteService.reenviarCorreo(
  //       user.token,
  //       cargo.idProceso,
  //       postulante.idPostulante
  //     );
  //     if (index + 1 === total) {
  //       setHabilitarEnvio(true);
  //     }
  //     setEnviados(index + 1);
  //   });
  // };

  const obtienePostulantesEtapa = (filtros = false) => {
    if (estado === 0) return postulantes;

    let postulantesEtapa = Array.from(
      postulantes.filter((postulante) => {
        return postulante.etapa == estado;
      })
    );
    if (filtros && postulantesFiltrados.length > 0) {
      let postulantesEtapaAux = [];
      for (let index = 0; index < postulantesEtapa.length; index++) {
        const postulante = postulantesEtapa[index];
        const found = postulantesFiltrados.find((element) => {
          return element.data[0] === postulante.idPostulante;
        });
        if (found !== undefined) {
          postulantesEtapaAux.push(postulante);
        }
      }
      postulantesEtapa = postulantesEtapaAux;
    }
    return postulantesEtapa;
  };

  const envioPanel = () => {
    datosPanel.fechaHora = datosPanel.fecha + " " + datosPanel.hora;
    spinnerModal(
      datosPanel.existePanel ? "Actualizando Panel." : "Enviando Panel."
    );
    setActualizar(actualizar);
    setTimeout(() => {
      PostulanteService.agregarResultadoPanel(user.token, datosPanel).then(
        (resp) => {
          if (resp.codeResponse === 200) {
            alertaModal(
              "info",
              `Panel ${
                datosPanel.existePanel ? "actualizado" : "enviado"
              } exitosamente.`
            );
          } else {
            alertaModal("error", "Se ha producido un error intente mas tarde.");
          }
        }
      );
    }, 500);
  };

  const formularioPanel = async (postulante) => {
    let resp = await PostulanteService.obtieneResultadoPanel(
      user.token,
      cargo.idProceso,
      postulante.idPostulante
    );
    let panel = resp.informe;
    datosPanel.idPostulante = postulante.idPostulante;
    datosPanel.idProceso = cargo.idProceso;
    datosPanel.comentario = panel.comentario !== null ? panel.comentario : "";
    datosPanel.comentarioEntrevistaPsicolaboral =
      panel.comentarioEntrevistaPsicolaboral !== null
        ? panel.comentarioEntrevistaPsicolaboral
        : "";
    datosPanel.comentarioExamenPreocupacional =
      panel.comentarioExamenPreocupacional !== null
        ? panel.comentarioExamenPreocupacional
        : "";
    datosPanel.entrevistaPsicolaboral =
      panel.entrevistaPsicolaboral !== null ? panel.entrevistaPsicolaboral : "";
    datosPanel.examenPreocupacional =
      panel.examenPreocupacional !== null ? panel.examenPreocupacional : "";
    datosPanel.panelistas = panel.panelistas !== null ? panel.panelistas : [];
    datosPanel.resultado = panel.resultado !== null ? panel.resultado : "";
    datosPanel.usuario = user.user;
    datosPanel.fechaHora = panel.fechaHora !== null ? panel.fechaHora : "";
    datosPanel.fecha = null;
    datosPanel.hora = null;
    datosPanel.existePanel =
      panel !== undefined &&
      panel.idInformePanel !== undefined &&
      panel.idInformePanel !== null
        ? true
        : false;
    openModal({
      tituloModal: "Evaluaciones",
      contenidoModal: (opciones) => {
        return <Evaluaciones datosPanel={datosPanel} />;
      },
      botonModal: () => {
        return (
          <CustomConfirmacion
            onPressCancelar={() => closeModal()}
            onPressAceptar={() => envioPanel()}
          />
        );
      },
    });
    setActualizar(!actualizar);
  };

  const formularioCompetencias = (postulante) => {
    PostulanteService.obtieneCompetencias(
      user.token,
      cargo.idProceso,
      postulante.idPostulante
    ).then((resp) => {
      if (resp.codigoRespuesta === 200) {
        let data = {
          idPostulante: postulante.idPostulante,
          idProceso: cargo.idProceso,
          listCompetencia: [],
        };

        resp.entidad.forEach((competencia) => {
          data.listCompetencia.push({
            evaluacion: competencia.evaluacionCompetencia,
            idCompetencia: competencia.idCompetencia,
          });
        });
        openModal({
          tituloModal: "Competencias",
          contenidoModal: (opciones) => {
            return (
              <div>
                {resp.entidad.map((competencia, index) => {
                  return (
                    <div key={index}>
                      <CustomTextInput
                        label={competencia.nombreCompetencia}
                        value={data.listCompetencia[index].evaluacion}
                        onChange={(text) =>
                          (data.listCompetencia[index].evaluacion =
                            parseInt(text))
                        }
                        valueType="numeros"
                        requiered
                        maxValue={competencia.escalaMayor}
                        minValue={competencia.escalaMenor}
                      />
                    </div>
                  );
                })}
              </div>
            );
          },
          botonModal: () => {
            return (
              <CustomButton
                onPress={() => enviaCompetencia(data)}
                textoButton="Aceptar"
              />
            );
          },
        });
      }
    });
  };

  const enviaCompetencia = (data) => {
    spinnerModal("Enviando evaluación de competencia.");
    setActualizar(actualizar);
    setTimeout(() => {
      PostulanteService.grabaEvaluacionCompetencia(user.token, data).then(
        (resp) => {
          if (resp.codigoRespuesta === 200) {
            alertaModal("info", "Evaluación de competencia asignada.");
          } else {
            alertaModal(
              "error",
              "Se ha producido un error, intente más tarde."
            );
          }
        }
      );
    }, 500);
  };

  const opciones = {
    filter: true,
    sort: true,
  };

  const opcionesDisplay = {
    filter: true,
    sort: true,
    display: false,
  };
  const opcionesDisplayTest = {
    filter: true,
    sort: true,
    display: 2 === estado || 3 === estado,
  };

  const valoresPosibles = (
    lista,
    nombreColumna,
    esArreglo = false,
    nombreCampo = null,
    index = null
  ) => {
    let valores = [];
    lista.forEach((elemento) => {
      if (esArreglo) {
        if (nombreCampo !== null) {
          if (elemento[nombreColumna] !== undefined) {
            elemento[nombreColumna].forEach((campo, i) => {
              if (campo != null && (index === null || index === i)) {
                valores.push(campo[nombreCampo]);
              }
            });
          }
        } else {
          valores = valores.concat(elemento[nombreColumna]);
        }
      } else {
        let valor = null;
        if (nombreCampo !== null) {
          valor = elemento[nombreColumna][nombreCampo];
        } else {
          valor = elemento[nombreColumna];
        }
        if (valor === null || valor === undefined) {
          valor = "Sin Dato";
        }
        valores.push(valor);
      }
    });
    valores = valores.filter((item, index) => {
      return valores.indexOf(item) === index;
    });
    return valores;
  };

  const modalCompetencias = (postulante) => {
    openModal({
      tituloModal: "Datos Postulante Informe",
      contenidoModal: (opciones) => {
        return (
          <ScrollView style={{ height: (windowHeight * 4) / 5 - 200 }}>
            {opciones.datosPostulante.map((item, index) => {
              return (
                <List.Item
                  key={index}
                  title={item.label}
                  onPress={() => {
                    if (item["selected"] === undefined) {
                      item["selected"] = true;
                    } else {
                      item["selected"] = !item["selected"];
                    }
                    if (index === 0) {
                      datosPostulante.forEach((dato) => {
                        dato["selected"] = item["selected"];
                      });
                    } else {
                      let total = 0;
                      datosPostulante.forEach((dato, index) => {
                        if (dato["selected"] === true && index > 0) {
                          total++;
                        }
                      });
                      if (total === datosPostulante.length - 1) {
                        datosPostulante[0]["selected"] = true;
                      } else {
                        datosPostulante[0]["selected"] = false;
                      }
                    }
                    setActualizar(!opciones.actualizar);
                  }}
                  left={(props) => {
                    return (
                      <List.Icon
                        icon={
                          item["selected"] !== undefined &&
                          item["selected"] === true
                            ? "checkbox-marked-outline"
                            : "checkbox-blank-outline"
                        }
                      />
                    );
                  }}
                />
              );
            })}
          </ScrollView>
        );
      },
      botonModal: () => {
        return (
          <CustomButton
            onPress={() => envioDatosPostulanteInforme(postulante)}
            textoButton="Aceptar"
          />
        );
      },
    });
  };

  const envioDatosPostulanteInforme = (postulante) => {
    let competencias = {
      idPostulante: postulante.idPostulante,
      idProceso: cargo.idProceso,
      listDatosPostulante: [],
    };

    datosPostulante.forEach((dato, index) => {
      if (dato["selected"] === true && index !== 0) {
        competencias.listDatosPostulante.push(parseInt(dato["value"]));
      }
    });

    DocumentosService.obtieneReporteCompetencias(
      user.token,
      competencias,
      postulante
    ).then((resp) => console.log(resp));
  };

  const columnas = () => {
    let columnasOriginal = [
      {
        name: "idPostulante",
        options: {
          display: "excluded",
          filter: false,
        },
      },
      {
        name: "Seleccionar",
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          setCellProps: (value) => {
            return {
              style: {
                position: "sticky",
                left: 0,
                backgroundColor: "#c3d1e2",
                zIndex: 101,
              },
            };
          },
          setCellHeaderProps: (value) => {
            return {
              style: {
                position: "sticky",
                left: 0,
                maxWidth: 75,
                minWidth: 75,
                color: Colors.white,
                zIndex: 102,
              },
            };
          },
          customHeadLabelRender: (columnMeta) => {
            return (
              <View>
                <Checkbox
                  uncheckedColor={"#ffffff"}
                  color={"#f6a623"}
                  disabled={
                    !(botonesFabGroup().length > 0 && etapa !== undefined)
                  }
                  status={seleccionaTodos ? "checked" : "unchecked"}
                  onPress={() => {
                    setSeleccionaTodos(!seleccionaTodos);
                    if (
                      postulantesFiltrados !== undefined &&
                      postulantesFiltrados.length > 0
                    ) {
                      postulantesFiltrados.forEach((dato) => {
                        let postulante =
                          obtienePostulantesEtapa()[dato.dataIndex];
                        postulante.check = !seleccionaTodos;
                      });
                    } else {
                      let postulantes = obtienePostulantesEtapa();
                      postulantes.forEach((postulante) => {
                        postulante.check = !seleccionaTodos;
                      });
                    }
                    setActualizar(!actualizar);
                  }}
                />
              </View>
            );
          },
          customBodyRenderLite: (dataIndex) => {
            let postulante = obtienePostulantesEtapa()[dataIndex];
            return (
              <View>
                <Checkbox
                  uncheckedColor={"#0a5dc1"}
                  color={"#f6a623"}
                  disabled={
                    !(botonesFabGroup().length > 0 && etapa !== undefined)
                  }
                  status={
                    postulante !== undefined && postulante.check
                      ? "checked"
                      : "unchecked"
                  }
                  onPress={() => {
                    if (postulante.check === undefined) {
                      postulante.check = true;
                    } else {
                      postulante.check = !postulante.check;
                    }
                    let postulantesSeleccionados = obtienePostulantesEtapa(
                      true
                    ).filter((postulante) => {
                      if (postulante.check === undefined) {
                        return false;
                      }
                      return postulante.check;
                    });
                    setSeleccionaTodos(
                      postulantesSeleccionados.length > 0 &&
                        postulantesSeleccionados.length ===
                          obtienePostulantesEtapa(true).length
                    );
                    setActualizar(!actualizar);
                  }}
                />
              </View>
            );
          },
        },
      },
      {
        name: "nombre",
        label: "Nombre",
        options: {
          ...opciones,
          viewColumns: false,
          customFilterListOptions: {
            render: (v) => `Nombre: ${v}`,
          },
          setCellProps: (value) => {
            return {
              style: {
                position: "sticky",
                left: 83,
                backgroundColor: "#c3d1e2",
                zIndex: 101,
              },
            };
          },
          setCellHeaderProps: (value) => {
            return {
              className: "prueba-nombre",
              style: {
                position: "sticky",
                left: 83,
                maxWidth: 120,
                minWidth: 120,
                color: Colors.white,
                zIndex: 102,
              },
            };
          },
        },
      },
      {
        name: "apPaterno",
        label: "Apellido Paterno",
        options: {
          ...opciones,
          viewColumns: false,
          customFilterListOptions: {
            render: (v) => `Apellido Paterno: ${v}`,
          },
          setCellProps: (value) => {
            return {
              style: {
                position: "sticky",
                left: 211,
                backgroundColor: "#c3d1e2",
                zIndex: 101,
              },
            };
          },
          setCellHeaderProps: (value) => {
            return {
              style: {
                position: "sticky",
                left: 211,
                maxWidth: 120,
                minWidth: 120,
                color: Colors.white,
                zIndex: 102,
              },
            };
          },
        },
      },
      {
        name: "apMaterno",
        label: "Apellido Materno",
        options: {
          ...opciones,
          viewColumns: false,
          customFilterListOptions: {
            render: (v) => `Apellido Materno: ${v}`,
          },
          setCellProps: (value) => {
            return {
              style: {
                position: "sticky",
                left: 339,
                backgroundColor: "#c3d1e2",
                zIndex: 101,
              },
            };
          },
          setCellHeaderProps: (value) => {
            return {
              style: {
                position: "sticky",
                left: 339,
                maxWidth: 120,
                minWidth: 120,
                color: Colors.white,
                zIndex: 102,
              },
            };
          },
        },
      },
      {
        name: "rut",
        label: "R. U. T.",
        options: {
          ...opciones,
          display: UserService.tieneRol(user, [
            Roles.ADMINISTRADOR,
            Roles.SUPER_ADMIN,
          ])
            ? true
            : "excluded",
          setCellProps: (value) => {
            return {
              style: {
                minWidth: "90px",
              },
            };
          },
          customFilterListOptions: {
            render: (v) => `R. U. T. : ${v}`,
          },
        },
      },
      {
        name: "nombreEtapa",
        label: "Estado Proceso",
        options: {
          ...opciones,
          customFilterListOptions: {
            render: (v) => `Estado Proceso: ${v}`,
          },
        },
      },
      {
        name: "nombreGenero",
        label: "Género",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Género: ${v}`,
          },
        },
      },
      {
        name: "nombreEstadoCivil",
        label: "Estado civil",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Estado civil: ${v}`,
          },
        },
      },
      {
        name: "fechaNacimiento",
        label: "Fecha Nacimiento",
        type: "fecha",
        options: {
          ...opcionesDisplay,
        },
      },
      {
        name: "ntelefonoMovil",
        label: "Teléfono Movil",
        options: {
          ...opciones,
          display: UserService.tieneRol(user, [
            Roles.ADMINISTRADOR,
            Roles.SUPER_ADMIN,
          ])
            ? true
            : "excluded",
          customFilterListOptions: {
            render: (v) => `Teléfono Móvil: ${v}`,
          },
        },
      },
      {
        name: "ntelefonoFijo",
        label: "Teléfono Fijo",
        options: {
          ...opcionesDisplay,
          display: UserService.tieneRol(user, [
            Roles.ADMINISTRADOR,
            Roles.SUPER_ADMIN,
          ])
            ? true
            : "excluded",
          customFilterListOptions: {
            render: (v) => `Teléfono Fijo: ${v}`,
          },
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          ...opciones,
          display: UserService.tieneRol(user, [
            Roles.ADMINISTRADOR,
            Roles.SUPER_ADMIN,
          ])
            ? true
            : "excluded",
          customFilterListOptions: {
            render: (v) => `Email: ${v}`,
          },
        },
      },
      {
        name: "referido",
        label: "Referido",
        options: {
          ...opciones,
          customFilterListOptions: {
            render: (v) => `Referido: ${v}`,
          },
        },
      },
      {
        name: "licencias",
        label: "Licencia de conducir",
        options: {
          filter: true,
          sort: false,
          display: false,
          filterOptions: {
            names: valoresPosibles(
              obtienePostulantesEtapa(),
              "licencias",
              true,
              "clase"
            ),
            logic: (location, filters, row) => {
              if (filters[0] && location !== undefined) {
                let licencia = location.find(
                  (licencia) => licencia.clase === filters[0]
                );
                return licencia === undefined ? true : false;
              }
              return false;
            },
          },
          customFilterListOptions: {
            render: (v) => `Licencia de conducir: ${v}`,
          },
          customBodyRenderLite: (dataIndex) => {
            let licencias = [];
            let postulante = obtienePostulantesEtapa()[dataIndex];
            if (postulante !== undefined) {
              postulante.licencias.forEach((licencia) => {
                licencias.push(licencia.clase);
              });
            }
            return (
              <div>
                {licencias.map((licencia, index) => {
                  return (
                    <List.Item
                      style={{ padding: 0 }}
                      key={index}
                      title={licencia}
                    />
                  );
                })}
              </div>
            );
          },
        },
      },
      {
        name: "experienciaLaboralRemunerada",
        label: "Experiencia Laboral Remunerada",
        options: {
          ...opcionesDisplay,
          filterOptions: {
            names: valoresPosibles(
              obtienePostulantesEtapa(),
              "experienciaLaboralRemunerada"
            ),
            logic: (location, filters, row) => {
              if (filters[0]) {
                return location !== filters[0];
              }
              return false;
            },
          },
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 =
                obj1.data === null || obj1.data === undefined ? 0 : obj1.data;
              let val2 =
                obj2.data === null || obj2.data === undefined ? 0 : obj2.data;
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          customFilterListOptions: {
            render: (v) => `Experiencia Laboral Remunerada: ${v}`,
          },
          customBodyRenderLite: (dataIndex) => {
            let postulante = obtienePostulantesEtapa()[dataIndex];
            if (postulante !== undefined) {
              return (
                <Text style={{ textTransform: "uppercase" }}>
                  {postulante.experienciaLaboralRemunerada}
                </Text>
              );
            }
          },
        },
      },
      {
        name: "direccion",
        label: "Dirección",
        options: {
          filter: true,
          sort: true,
          display: UserService.tieneRol(user, [
            Roles.ADMINISTRADOR,
            Roles.SUPER_ADMIN,
          ])
            ? false
            : "excluded",
          customFilterListOptions: {
            render: (v) => `Dirección: ${v}`,
          },
        },
      },
      {
        name: "nombreCiudad",
        label: "Ciudad",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Ciudad: ${v}`,
          },
        },
      },
      {
        name: "entrevistaPsicolaboral",
        label: "Entrevista Psicolaboral",
        options: {
          ...opcionesDisplayTest,
          customFilterListOptions: {
            render: (v) => `Entrevista Psicolaboral: ${v}`,
          },
        },
      },
      {
        name: "examenPreocupacional",
        label: "Examen Preocupacional",
        options: {
          ...opcionesDisplayTest,
          customFilterListOptions: {
            render: (v) => `Examen Preocupacional: ${v}`,
          },
        },
      },
      {
        name: "panel",
        label: "Entrevista Panel",
        options: {
          ...opcionesDisplayTest,
          customFilterListOptions: {
            render: (v) => `Entrevista Panel: ${v}`,
          },
        },
      },
      {
        name: "nombreRegion",
        label: "Región",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Región: ${v}`,
          },
        },
      },
      {
        name: "estadoTestDisc",
        label: "Test Disc",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Test Disc: ${v}`,
          },
        },
      },
      {
        name: "estadoTestWonderlic",
        label: "Test Wonderlic",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Test Wonderlic: ${v}`,
          },
        },
      },
      {
        name: "resultadoTestWonderlic",
        label: "Resultado Test Wonderlic",
        options: {
          mayorA: true,
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Resultado Test Wonderlic mayor o igual a: ${v}`,
          },
        },
      },
      {
        name: "estadoTestPrp",
        label: "Test PRP",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Test Prp: ${v}`,
          },
        },
      },
      {
        name: "resultadoTestPrp",
        label: "Resultado Test PRP",
        options: {
          mayorA: true,
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Resultado Test PRP mayor o igual a: ${v}`,
          },
        },
      },
      {
        name: "estadoTestBarrat",
        label: "Test Barrat",
        options: {
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Test Barrat: ${v}`,
          },
        },
      },
      {
        name: "resultadoTestBarrat",
        label: "Resultado Test Barrat",
        options: {
          mayorA: true,
          ...opcionesDisplay,
          customFilterListOptions: {
            render: (v) => `Resultado Test Barrat mayor o igual a: ${v}`,
          },
        },
      },
      {
        name: "expectativaRenta",
        label: "Expectativa Renta",
        options: {
          mayorA: true,
          ...opciones,
          customFilterListOptions: {
            render: (v) => `Expectativa Renta mayor o igual a: ${v}`,
          },
        },
      },
      {
        name: "fechaPostulacion",
        label: "Fecha Postulación",
        type: "fecha",
        options: {
          ...opcionesDisplay,
        },
      },
      ...tablaPreguntasEmpresa(opciones),
      ...tablaPreguntasCargo(opciones),
      {
        name: "estrellas",
        label: "Valoración",
        options: {
          ...opciones,
          filterOptions: {
            names: valoresPosibles(obtienePostulantesEtapa(), "estrellas"),
            logic: (location, filters, row) => {
              if (filters[0] && filters[0] === "Sin Dato") {
                if (typeof location === "string") {
                  return (
                    location !== null &&
                    location !== undefined &&
                    location.trim().length > 0
                  );
                } else {
                  return location !== null && location !== undefined;
                }
              }
              if (filters[0] || typeof filters[0] === "number") {
                return location !== filters[0];
              }
              return false;
            },
          },
          display: estado > 0 ? true : "excluded",
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let pos = obj1.rowData.length - 2;
              let val1 =
                obj1.rowData[pos] === null || obj1.rowData[pos] === undefined
                  ? 0
                  : obj1.rowData[pos];
              let val2 =
                obj2.rowData[pos] === null || obj2.rowData[pos] === undefined
                  ? 0
                  : obj2.rowData[pos];
              return (val1 - val2) * (order === "asc" ? 1 : -1);
            };
          },
          customFilterListOptions: {
            render: (v) => `Valoración: ${v}`,
          },
          customBodyRenderLite: (dataIndex) => {
            let postulante = obtienePostulantesEtapa()[dataIndex];
            let estrellas = postulante === undefined ? 0 : postulante.estrellas;
            return (
              <View style={{ flexDirection: "row" }}>
                <IconButton
                  key={100}
                  icon={"star-off"}
                  color={"#545454"}
                  size={22}
                  style={{ margin: 0, width: 25 }}
                  onPress={() =>
                    ratingPostulante(obtienePostulantesEtapa()[dataIndex], -1)
                  }
                />
                {Array.from("x".repeat(5)).map((element, rating) => (
                  <IconButton
                    key={rating}
                    icon={rating < estrellas ? "star" : "star-outline"}
                    color={Colors.yellow700}
                    size={22}
                    style={{ margin: 0, width: 25 }}
                    onPress={() =>
                      ratingPostulante(
                        obtienePostulantesEtapa()[dataIndex],
                        rating
                      )
                    }
                  />
                ))}
              </View>
            );
          },
        },
      },
      {
        name: "Acción",
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          customBodyRenderLite: (dataIndex) => {
            let postulante = obtienePostulantesEtapa()[dataIndex];
            return (
              <View style={{ flexDirection: "row" }}>
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                  Roles.VISUALIZADOR,
                  Roles.ADMIN_EMPRESA,
                ]) ||
                  (UserService.tieneRol(user, [Roles.PSICOLOGO]) &&
                    (estado === 1 || estado === 2))) && (
                  <div title="Ver Detalles">
                    <IconButton
                      icon="magnify"
                      color={"#545454"}
                      size={20}
                      style={{ alignSelf: "flex-end" }}
                      onPress={() => viewDetails(postulante)}
                    />
                  </div>
                )}
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                ]) ||
                  (UserService.tieneRol(user, [
                    Roles.PSICOLOGO,
                    Roles.SUPER_ADMIN,
                    Roles.ADMINISTRADOR,
                  ]) &&
                    (estado === 1 || estado === 2))) && (
                  <div title="Formulario Evaluaciones">
                    <IconButton
                      icon="format-list-bulleted-square"
                      color={"#545454"}
                      size={20}
                      style={{ alignSelf: "flex-end" }}
                      onPress={() => formularioPanel(postulante)}
                    />
                  </div>
                )}
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                ]) ||
                  (UserService.tieneRol(user, [Roles.PSICOLOGO]) &&
                    (estado === 1 || estado === 2))) && (
                  <div title="Referido">
                    <IconButton
                      icon="account-switch"
                      color={
                        postulante !== undefined && postulante.referido === "SI"
                          ? "#f6a623"
                          : "#545454"
                      }
                      size={20}
                      style={{ alignSelf: "flex-end" }}
                      onPress={() => {
                        PostulanteService.guardarReferido(
                          user.token,
                          cargo.idProceso,
                          postulante.idPostulante
                        ).then((respuesta) => {
                          if (respuesta.codigoRespuesta === 200) {
                            alertaModal(
                              "info",
                              `Postulante ${postulante.nombre} ${postulante.apPaterno} se actualizo correctamente`
                            );
                            postulante.referido = respuesta.resultadoReferido;
                          } else {
                            alertaModal(
                              "error",
                              `Error al actualizar al postulante ${postulante.nombre} ${postulante.apPaterno} `
                            );
                          }
                        });
                      }}
                    />
                  </div>
                )}
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                ]) ||
                  UserService.tieneRol(user, [Roles.PSICOLOGO])) &&
                  estado === 4 && (
                    <div title="Seleccionado">
                      <IconButton
                        icon="account-check"
                        color={
                          postulante !== undefined &&
                          postulante.seleccionado === "SI"
                            ? "#f6a623"
                            : "#545454"
                        }
                        size={20}
                        style={{ alignSelf: "flex-end" }}
                        onPress={() => {
                          PostulanteService.seleccionaPostulante(
                            user.token,
                            cargo.idProceso,
                            postulante.idPostulante
                          ).then((respuesta) => {
                            if (respuesta.codigoRespuesta === 200) {
                              alertaModal(
                                "info",
                                `Postulante ${postulante.nombre} ${
                                  postulante.apPaterno
                                } fue ${
                                  postulante.seleccionado === "SI"
                                    ? "deseleccionado"
                                    : "seleccionado"
                                }`
                              );
                              postulante.seleccionado =
                                respuesta.resultadoSeleccionado;
                            } else {
                              alertaModal(
                                "error",
                                `Error al ${
                                  postulante.seleccionado === "SI"
                                    ? "deseleccionar"
                                    : "seleccionar"
                                } al postulante ${postulante.nombre} ${
                                  postulante.apPaterno
                                } `
                              );
                            }
                          });
                        }}
                      />
                    </div>
                  )}
                {UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                  Roles.PSICOLOGO,
                ]) &&
                  estado === 3 && (
                    <div title="Competencias">
                      <IconButton
                        icon="account-star"
                        color={"#545454"}
                        size={20}
                        style={{ alignSelf: "flex-end" }}
                        onPress={() => formularioCompetencias(postulante)}
                      />
                    </div>
                  )}
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                  Roles.VISUALIZADOR,
                  Roles.ADMIN_EMPRESA,
                ]) ||
                  (UserService.tieneRol(user, [Roles.PSICOLOGO]) &&
                    (estado === 1 || estado === 2))) && (
                  <div title="Resultado Test">
                    <IconButton
                      icon="file-download"
                      color={"#545454"}
                      size={20}
                      style={{ alignSelf: "flex-end" }}
                      onPress={() => {
                        descargarTest(postulante);
                      }}
                    />
                  </div>
                )}
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                ]) ||
                  (UserService.tieneRol(user, [Roles.PSICOLOGO]) &&
                    (estado === 1 || estado === 2))) && (
                  <div title="Informe Postulante Competencias">
                    <IconButton
                      icon="file-chart"
                      color={"#545454"}
                      size={20}
                      style={{ alignSelf: "flex-end" }}
                      onPress={() => {
                        modalCompetencias(postulante);
                      }}
                    />
                  </div>
                )}
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                  Roles.ADMIN_EMPRESA,
                ]) ||
                  (UserService.tieneRol(user, [Roles.PSICOLOGO]) &&
                    (estado === 1 || estado === 2))) && (
                  <div title="Subir Archivo">
                    <IconButton
                      accessibilityLabel="Subir Archivo"
                      icon="upload"
                      color={"#545454"}
                      size={20}
                      style={{ alignSelf: "flex-end" }}
                      onPress={() => subirArchivo(postulante)}
                    />
                  </div>
                )}
                {(UserService.tieneRol(user, [
                  Roles.SUPER_ADMIN,
                  Roles.ADMINISTRADOR,
                  Roles.VISUALIZADOR,
                  Roles.ADMIN_EMPRESA,
                ]) ||
                  (UserService.tieneRol(user, [Roles.PSICOLOGO]) &&
                    (estado === 1 || estado === 2))) && (
                  <div title="Descargar Archivo">
                    <IconButton
                      accessibilityLabel="Descargar Archivo"
                      tap
                      icon="download"
                      color={"#545454"}
                      size={20}
                      style={{ alignSelf: "flex-end" }}
                      onPress={() => descargaArchivos(postulante)}
                    />
                  </div>
                )}
              </View>
            );
          },
        },
      },
    ];
    columnasOriginal.forEach((columna) => {
      let columnaView = columnasView[estado][columna.name];
      if (columnaView === undefined) {
        columnaView = columnasView[estado][columna.label];
      }
      if (columnaView !== undefined) {
        columna.options.display = columnaView === "add" ? true : false;
      }
    });

    return columnasOriginal;
  };

  const tablaPreguntasCargo = (opciones) => {
    let preguntas = [];
    let respuestasPreguntasCargo = [];
    let postulantesEtapa = obtienePostulantesEtapa();
    postulantesEtapa.forEach((postulante) => {
      if (
        postulante.respuestasPreguntasCargo !== null &&
        postulante.respuestasPreguntasCargo !== undefined
      ) {
        respuestasPreguntasCargo = respuestasPreguntasCargo.concat(
          postulante.respuestasPreguntasCargo
        );
      }
    });
    if (
      postulantesEtapa.length > 0 &&
      postulantes[0].respuestasPreguntasCargo !== null &&
      postulantes[0].respuestasPreguntasCargo !== undefined
    ) {
      postulantes[0].respuestasPreguntasCargo.forEach((element, index) => {
        if (element !== null) {
          preguntas.push({
            name: "respuestasPreguntasCargo",
            label: `${element.glosa}`,
            options: {
              ...opciones,
              display: 2 === estado || 3 === estado,
              customBodyRender: (value, tableMeta, updateValue) => {
                let postulante = postulantesEtapa[tableMeta.rowIndex];
                let pregunta = undefined;
                if (
                  postulante.respuestasPreguntasCargo !== null &&
                  postulante.respuestasPreguntasCargo !== undefined
                ) {
                  pregunta = postulante.respuestasPreguntasCargo.find(
                    (p) =>
                      p !== null &&
                      preguntas !== undefined &&
                      p["glosa"] === element.glosa
                  );
                }
                return pregunta !== undefined ? pregunta.respuesta : "";
              },
              customFilterListOptions: {
                render: (v) => `${element.glosa}: ${v}`,
              },
              filterOptions: {
                names: valoresPosibles(
                  obtienePostulantesEtapa(),
                  "respuestasPreguntasCargo",
                  true,
                  "respuesta",
                  index
                ),
                logic: (location, filters, row) => {
                  if (
                    filters[0] &&
                    row[30 + (index + 1)][index] !== undefined
                  ) {
                    let pregunta = row[30 + (index + 1)][index];
                    return pregunta.respuesta !== filters[0];
                  }
                  return false;
                },
              },
            },
          });
        }
      });
    }

    return preguntas;
  };
  const tablaPreguntasEmpresa = (opciones) => {
    let preguntas = [];
    let respuestasPreguntasEmpresa = [];
    let postulantesEtapa = obtienePostulantesEtapa();
    postulantesEtapa.forEach((postulante) => {
      if (
        postulante.respuestasPreguntasEmpresa !== null &&
        postulante.respuestasPreguntasEmpresa !== undefined
      ) {
        respuestasPreguntasEmpresa = respuestasPreguntasEmpresa.concat(
          postulante.respuestasPreguntasEmpresa
        );
      }
    });

    if (
      postulantesEtapa.length > 0 &&
      postulantes[0].respuestasPreguntasEmpresa !== null &&
      postulantes[0].respuestasPreguntasEmpresa !== undefined
    ) {
      postulantes[0].respuestasPreguntasEmpresa.forEach((element, index) => {
        if (element !== null) {
          preguntas.push({
            name: "respuestasPreguntasEmpresa",
            label: `${element.glosa}`,
            options: {
              ...opciones,
              display: 2 === estado || 3 === estado,
              customBodyRender: (value, tableMeta, updateValue) => {
                let postulante = postulantesEtapa[tableMeta.rowIndex];
                let pregunta = undefined;
                if (
                  postulante.respuestasPreguntasEmpresa !== null &&
                  postulante.respuestasPreguntasEmpresa !== undefined
                ) {
                  pregunta = postulante.respuestasPreguntasEmpresa.find(
                    (p) =>
                      p !== null &&
                      preguntas !== undefined &&
                      p["glosa"] === element.glosa
                  );
                }
                return pregunta !== undefined ? pregunta.respuesta : "";
              },
              customFilterListOptions: {
                render: (v) => `${element.glosa}: ${v}`,
              },
              filterOptions: {
                names: valoresPosibles(
                  obtienePostulantesEtapa(),
                  "respuestasPreguntasEmpresa",
                  true,
                  "respuesta",
                  index
                ),
                logic: (location, filters, row) => {
                  if (
                    filters[0] &&
                    row[30 + (index + 1)][index] !== undefined
                  ) {
                    let pregunta = row[30 + (index + 1)][index];
                    return pregunta.respuesta !== filters[0];
                  }
                  return false;
                },
              },
            },
          });
        }
      });
    }

    return preguntas;
  };

  const obtieneListaDatosPostulante = () => {
    EmpresaService.datosAdicionales(user.token, "postulante").then((resp) => {
      if (resp.codigoRespuesta === 200) {
        setDatosPostulante([{ label: "Todos", value: "-1" }, ...resp.entidad]);
      }
    });
  };

  const downloadFileWonderlic = () => {
    let data = [];

    if (postulantesFiltrados !== undefined && postulantesFiltrados.length > 0) {
      postulantesFiltrados.forEach((dato) => {
        let postulante = obtienePostulantesEtapa()[dato.dataIndex];
        data.push({
          RUT: postulante.rut,
          NOMBRE: `${postulante.nombre} ${postulante.apPaterno} ${postulante.apMaterno}`,
          RESULTADO: postulante.resultadoTestWonderlic,
        });
      });
    } else {
      let postulantes = obtienePostulantesEtapa(true);
      postulantes.forEach((postulante) => {
        data.push({
          RUT: postulante.rut,
          NOMBRE: `${postulante.nombre} ${postulante.apPaterno} ${postulante.apMaterno}`,
          RESULTADO: postulante.resultadoTestWonderlic,
        });
      });
    }
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Wonderlic");
    XLSX.writeFile(wb, "Repsultados_Test_Wonderlic.xlsx");
  };

  const obtieneDatosProceso = () => {
    spinnerModal("Generando Documento.");
    setActualizar(!actualizar);
    EmpresaService.obtieneDatosProceso(user.token, [cargo.idProceso]).then(
      (resp) => {
        alertaModal("info", "Documento Generado");
        setActualizar(actualizar);
      }
    );
  };

  React.useEffect(() => {
    setHeaderText(labels[estado].header);
    if (empresas.length > 0 && showSpinnerEmpresas) {
      setShowSpinnerEmpresas(false);
      setActualizar(!actualizar);
    }
    if (datosPostulante.length === 0) {
      obtieneListaDatosPostulante();
    }
    if (proceso !== undefined && proceso.recarga) {
      spinnerModal("Obteniendo postulantes.");
      setActualizar(actualizar);
      proceso.recarga = false;
      let empresa = empresas.find((emp) => emp.id === proceso.id_empresa);
      setEmpresa(empresa);
      obtieneCargos(empresa, proceso.id_proceso);
    }
  }, [
    actualizar,
    enviados,
    enviadosError,
    psicologoSelecionado,
    empresas.length,
    proceso?.recarga,
  ]);

  const labels = obtieneLabels();

  const botonesFabGroup = () => {
    let botones = [];
    /*if (
      UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.PSICOLOGO,
      ]) &&
      estado === 2
    ) {
      botones.push({
        icon: "email-send-outline",
        label: "Reenviar correo",
        style: { backgroundColor: "#f47c06" },
        color: Colors.white,
        onPress: () => reenviarCorreo(),
      });
    }*/
    if (
      UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.PSICOLOGO,
      ]) &&
      estado === 2
    ) {
      botones.push({
        icon: "file-document",
        label: "Asignar Test",
        style: { backgroundColor: "#f47c06" },
        color: Colors.white,
        onPress: () => asignarTest(),
      });

      botones.push({
        icon: "file-download",
        label: "Descargar Test Wonderlic",
        style: { backgroundColor: "#f47c06" },
        color: Colors.white,
        onPress: () => downloadFileWonderlic(),
      });
    }
    if (
      UserService.tieneRol(user, [Roles.SUPER_ADMIN, Roles.ADMINISTRADOR]) &&
      (estado === 1 || estado === 2 || estado === 3)
    ) {
      botones.push({
        icon: "account-plus",
        label: "Asignar psicólogo",
        style: { backgroundColor: "#f47c06" },
        color: Colors.white,
        onPress: () => asignarPsicologo(),
      });
    }
    if (
      UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.PSICOLOGO,
      ]) &&
      (estado === 1 || estado === 2 || estado === 3)
    ) {
      botones.push({
        icon: "send",
        label: "Avanzar a " + nombreSigEtapa,
        style: { backgroundColor: "#f47c06" },
        color: Colors.white,
        onPress: () => enviarEtapaSiguiente(),
      });
    }
    if (
      UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.PSICOLOGO,
      ]) &&
      (estado === 2 || estado === 3 || estado === 4)
    ) {
      botones.push({
        icon: "send",
        label: "Retroceder a " + nombreAntEtapa,
        style: { backgroundColor: "#f47c06", rotate: "180deg" },
        color: Colors.white,
        onPress: () => enviarEtapaAnterior(),
      });
    }
    if (
      UserService.tieneRol(user, [
        Roles.SUPER_ADMIN,
        Roles.ADMINISTRADOR,
        Roles.PSICOLOGO,
      ]) &&
      estado === 0
    ) {
      botones.push({
        icon: "file-download",
        label: "Descargar Datos Proceso",
        style: { backgroundColor: "#f47c06" },
        color: Colors.white,
        onPress: () => obtieneDatosProceso(),
      });
    }
    return botones;
  };

  return (
    <View>
      <BotonesEmpresaCargo
        empresas={empresas}
        empresa={empresa}
        cargos={cargos}
        cargo={cargo}
        selectEmpresa={selectEmpresa}
        selectCargo={selectCargo}
      />
      {Platform.OS === "web" && (
        <ScrollView style={styles.postulanteScrollview}>
          <CustomStepIndicator
            labels={obtieneLabels()}
            label={route.name}
            postulantes={postulantes}
            navigation={navigation}
            etapas={etapas}
          />
          {showSpinner && <LoadingIndicator texto={"Cargando Postulantes"} />}
          {showSpinnerEmpresas && (
            <LoadingIndicator texto={"Cargando Empresas"} />
          )}
          {obtienePostulantesEtapa().length > 0 && (
            <CustomMuiDataTable
              onDataFilterChange={(data) => {
                desseleccionaTodos();
                setPostulantesFiltrados(data);
              }}
              padding={0}
              height={"calc(100vh - 387.7px)"}
              data={obtienePostulantesEtapa()}
              columnas={columnas(
                obtienePostulantesEtapa(),
                estado,
                botonesFabGroup().length > 0 && etapa !== undefined
              )}
              options={{
                onViewColumnsChange: (changedColumn, action) => {
                  columnasView[estado][changedColumn] = action;
                },
                onRowSelectionChange: (
                  currentRowsSelected,
                  allRowsSelected
                ) => {
                  obtienePostulantesEtapa().forEach((postulante) => {
                    postulante.seleccionado = false;
                  });

                  allRowsSelected.forEach((row) => {
                    let index = row.index;
                    postulantes[index].seleccionado = true;
                  });
                  setActualizar(!actualizar);
                },
              }}
            />
          )}
        </ScrollView>
      )}
      <Dialog
        visible={visible}
        dismissable={opcionesModal.dismissable}
        onDismiss={closeModal}
        style={styles.modal}
      >
        <Dialog.Title>{opcionesModal.tituloModal}</Dialog.Title>
        <Dialog.Content>
          {opcionesModal.contenidoModal({
            enviados: enviados,
            enviadosError: enviadosError,
            psicologosFiltrados: psicologosFiltrados,
            psicologoSelecionado: psicologoSelecionado,
            testSeleccionado: testSeleccionado,
            test: test,
            archivo: archivo,
            fecha: fecha,
            hora: hora,
            datosPanel: datosPanel,
            actualizar: actualizar,
            datosPostulante: datosPostulante,
          })}
        </Dialog.Content>
        <Dialog.Actions>
          {opcionesModal.botonModal({
            habilitarEnvio: habilitarEnvio,
            habilitarPsicologoSeleccionado: psicologoSelecionado !== null,
            psicologoSelecionado: psicologoSelecionado,
            testSeleccionado: testSeleccionado,
            archivo: archivo,
          })}
        </Dialog.Actions>
      </Dialog>
      {etapa !== undefined &&
        totalSeleccionados() > 0 &&
        botonesFabGroup().length > 0 && (
          <CustomFabGroup
            visible={totalSeleccionados() > 0}
            listaBotones={botonesFabGroup()}
          />
        )}
      {etapa === undefined && botonesFabGroup().length > 0 && (
        <CustomFabGroup
          visible={estado === 0}
          listaBotones={botonesFabGroup()}
        />
      )}
      {etapa !== undefined && totalSeleccionados() > 0 && (
        <Badge
          style={{
            position: "absolute",
            margin: 20,
            right: 15,
            bottom: 95,
          }}
        >
          {totalSeleccionados()}
        </Badge>
      )}
    </View>
  );
}
