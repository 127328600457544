import { Platform } from "react-native";
import * as FileSystem from "expo-file-system";
import * as IntentLauncher from "expo-intent-launcher";
import { REACT_APP_URL_BASE_API_POSTULANTE } from "@env";

const respuesta = {
  mensaje: null,
  response: null,
  url: null,
};

export default {
  obtieneDocumento: (token, archivo) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: token,
      },
    };
    return new Promise(async (resolve, reject) => {
      if (Platform.OS === "web") {
        fetch(
          `${REACT_APP_URL_BASE_API_POSTULANTE}documentos/download/${archivo.idDocumento}`,
          requestOptions
        )
          .then((response) => response.blob())
          .then((blob) => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = archivo.fileName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); //afterwards we remove the element again
            resolve(respuesta);
          });
      } else {
        const callback = (downloadProgress) => {
          const progress =
            downloadProgress.totalBytesWritten /
            downloadProgress.totalBytesExpectedToWrite;
          console.log(progress);
        };

        const downloadResumable = FileSystem.createDownloadResumable(
          `${REACT_APP_URL_BASE_API_POSTULANTE}documentos/download/${archivo.idDocumento}`,
          FileSystem.cacheDirectory + archivo.nombre,
          {},
          callback
        );

        try {
          const { uri } = await downloadResumable.downloadAsync();
          console.log("Finished downloading to ", uri);
          //Linking.openURL(uri);
          FileSystem.getContentUriAsync(uri).then((cUri) => {
            console.log(cUri);
            IntentLauncher.startActivityAsync("android.intent.action.VIEW", {
              data: cUri,
              flags: 1,
            });
          });
        } catch (e) {
          //console.error(e);
        }
      }
    });
  },
  obtieneDocumentoTestDisc: (token, idProceso, idPostulante) => {
    //Correciones
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}test/descargar/evaluacion/${idProceso}/idPostulante/${idPostulante}`,
        requestOptions
      ).then((resp) => {
        respuesta.response = resp;
        respuesta.url = `${REACT_APP_URL_BASE_API_POSTULANTE}test/descargar/evaluacion/${idProceso}/idPostulante/${idPostulante}`;
        resolve(respuesta);
      });
    });
  },
  obtieneDocumentoTest: (token, uuid) => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}test/resultado/${uuid}`,
        requestOptions
      ).then((resp) => {
        respuesta.response = resp;
        respuesta.url = `${REACT_APP_URL_BASE_API_POSTULANTE}test/resultado/${uuid}`;
        resolve(respuesta);
      });
    });
  },
  listaDocumentos: (token, idPostulante) => {
    const requestOptions = {
      headers: {
        method: "GET",
        Authorization: token,
      },
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}documentos/${idPostulante}`,
        requestOptions
      ).then((resp) => resolve(resp.json()));
    });
  },
  cargarDocumento: (token, idPostulante, files) => {
    let postulante = new FormData();
    let archivos = [...files];
    for (const index in archivos) {
      postulante.append("file", archivos[index]);
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        //'Content-Type': 'multipart/form-data'
      },
      body: postulante,
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}documentos/${idPostulante}`,
        requestOptions
      ).then((resp) => resolve(resp.json()));
    });
  },
  obtieneReporteCompetencias: (token, competencias, postulante) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(competencias),
    };
    return new Promise((resolve, reject) => {
      fetch(
        `${REACT_APP_URL_BASE_API_POSTULANTE}reporte/excel/competencias`,
        requestOptions
      )
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `Reporte_Competencias_${postulante.nombre}_${postulante.apPaterno}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          resolve(respuesta);
        });
    });
  },
};
