import React from "react";
import { Image, Platform, Text, View } from "react-native";
import { Headline, TextInput } from "react-native-paper";
import CustomButton from "../components/CustomButton";
import UserService from "../services/UserService";
import style from "../styles/style";
import theme from "../styles/theme";
import Logos from "../utils/imagenes";
import InfoAmbiente from "./InfoAmbiente";

export default function Login({ navigation }) {
  const [user, setUser] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [ocultarPass, setOcultarPass] = React.useState(true);
  const [cargando, setCargando] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");

  const login = () => {
    setCargando(true);
    UserService.autenticate(user, pass).then((respuesta) => {
      setCargando(false);
      if (respuesta.codigoRespuesta === 0) {
        setUser("");
        setPass("");
        navigation.navigate("Home", { user: respuesta.entidad });
      } else {
        setMensaje(respuesta.mensaje);
      }
    });
  };

  const limpiaMensaje = () => {
    if ((mensaje, mensaje !== "")) {
      setMensaje("");
    }
  };

  React.useEffect(() => {}, []);

  return (
    <View style={style.container}>
      <InfoAmbiente />
      <View style={style.login}>
        {Platform.OS === "web" && (
          <div>
            <img
              src={Logos.logoNuevo}
              style={{ marginBottom: 20, width: 150 }}
            />
          </div>
        )}
        {Platform.OS !== "web" && (
          <Image style={style.imagen} source={Logos.logoNuevo} />
        )}
        <Headline style={style.titulo}>Iniciar Sesión</Headline>
        {mensaje !== "" ? (
          <Text style={style.errorLogin}>{mensaje}</Text>
        ) : (
          <Text></Text>
        )}
        <TextInput
          value={user}
          onFocus={limpiaMensaje}
          onChangeText={(text) => setUser(text)}
          style={style.input}
          mode="flat"
          label="Usuario"
          theme={theme}
          underlineColor="transparent"
          underlineColorAndroid="transparent"
          right={<TextInput.Icon name="account" color="#000" />}
        />
        <TextInput
          value={pass}
          secureTextEntry={ocultarPass}
          onFocus={limpiaMensaje}
          onChangeText={(text) => setPass(text)}
          style={style.input}
          mode="flat"
          label="Password"
          theme={theme}
          underlineColor="transparent"
          underlineColorAndroid="transparent"
          right={
            <TextInput.Icon
              color="#000"
              name={ocultarPass ? "eye-off" : "eye"}
              onPress={() => setOcultarPass(!ocultarPass)}
            />
          }
        />
        <CustomButton
          onPress={login}
          loading={cargando}
          textoButton="Iniciar Sesión"
          style={{ marginTop: 20 }}
        />
      </View>
    </View>
  );
}
