import React from "react";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import ColoresPineal from "../styles/ColoresPineal";

export default function LoadingIndicator({ texto }) {
  return (
    <View style={{ justifyContent: "center" }}>
      <ActivityIndicator
        animating={true}
        color={ColoresPineal.anaranjado}
        size={80}
        style={{ marginTop: 40, marginBottom: 20 }}
      />
      <Text
        style={{
          color: "#6b6b6b",
          textAlign: "center",
          fontSize: 20,
          textTransform: "uppercase",
        }}
      >
        {texto}
      </Text>
    </View>
  );
}
